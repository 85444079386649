import request from "@/utils/request";
// 课程包列表
export const getListAPI = (obj) => {
  return request.post("/api/CoursePackage/List", obj);
};
// 获取课程包详情
export const getListItemsAPI = (id) => {
  return request.get(`/api/CoursePackage?id=${id}`);
};
export const getList2API = (obj,tyepid) => {
  return request.post("/api/CoursePackage/List2?typeId="+tyepid, obj);
};
//  url: `/api/Questionnaire?para=${id}`;

// export function getListItemsAPI(id) {
//   return request({
//     url: `/api/CoursePackage?para=${id}`,
//   });
// }
