<template>
  <div class="container" ref="wrapper">
    <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-if="loading" size="24px">加载中...
    </van-loading>
    <div v-if="loading == false">
      <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
      </van-nav-bar>

      <van-collapse v-model="activeName" accordion>
        <van-collapse-item :title="item.title" :name="item.id" v-for="item in list" :key="item.id"
          :value="`${item.progress}`">
          <van-cell v-for="(i) in item.itemList" :key="i.id" :title="i.title" 
            :value="i.progress"  @click="toClick(i.itemType, i.itemId, item.typeId)" />
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { getListAPI, getListItemsAPI } from "../../api/coursePackage";

export default {
  data() {
    return {
      scroll: 0,
      obj: {
        fromType: 1,
        current: 1,
        pageSize: 100,
        FilterData: {
          setTypes: [200, 201, 202, 203, 204, 205],
        },
      },
      title: "",
      //   初始化数据
      list: [],
      //   id: this.$route.query.id,
      activeName: this.$route.query.id,
      loading: true,
    };
  },
  computed: {},
  created() {
    // console.log();
    this.initgetList();
  },

  activated() {
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },

  watch: {},
  methods: {
    async initgetList() {
      //   console.log(123);
      if (this.$route.query.id) {
        let result = await getListItemsAPI(this.$route.query.id);
        // console.log(result.data);
        delete result.data.success;
        this.list = result.data;
        this.title = result.data.data.title;
        this.loading = false;
      } else {
        let result = await getListAPI(this.obj);
        let { data } = result.data;
        // console.log(data);
        // for (let i = 0; i < data.length; i++) {
        //   if (data[i].progress == "") {
        //     data[i].progress == "1231";
        //   }
        // }
        this.list = data.reverse();
        // 关闭加载动画
        this.loading = false;
      }
    },

    // 数字转中文
    numberToChinese(num) {
      var AA = new Array(
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十"
      );
      var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
      var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
      for (let i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
          case 0:
            re = BB[7] + re;
            break;
          case 4:
            if (
              !new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$").test(a[0])
            )
              re = BB[4] + re;
            break;
          case 8:
            re = BB[5] + re;
            BB[7] = BB[5];
            k = 0;
            break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
          re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
      }

      if (a.length > 1) {
        // 加上小数部分(如果有小数部分)
        re += BB[6];
        for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
      }
      if (re == "一十") re = "十";
      if (re.match(/^一/) && re.length == 3) re = re.replace("一", "");
      return re;
    },
    toClick(type, id, ptypeId) {

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;
      if (ptypeId == 400) {
        id = id.toString();
        if (type === 1) {
          this.$router.push({
            path: "/course/detail",
            query: { para: id },
            date: new Date().getTime(),
          });
        }
        if (type === 2) {
          this.$router.push({
            path: "/course/courseofflinedetail",
            query: { para: id },
            date: new Date().getTime(),
          });
        }
      }
      if(ptypeId==401){
        this.$router.push({
            path: "/article/detail",
            query: { para: id,back:true,},
          });
      }
    },
  },
};
</script>

<style scoped>
.container {
  overflow-y: auto;
  box-sizing: border-box;
}
.van-cell__title, .van-cell__value
{
  -webkit-flex: unset;
  flex:unset;
}
.van-cell__label
{
  color:#000;
  font-weight: 400;
  font-size: 14px;
    line-height: 24px;
}
.van-cell__value
{
  color: #9b1f24;
}


.loading {
  text-align: center;
}
</style>
